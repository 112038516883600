.footer {
  position: relative;
  padding: 50px 0;
  height: 350px;
  background: center / cover no-repeat;
  border-top: 3px solid $main;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to top, transparent, #000);
    z-index: 0;
  }
}

.credits {
  padding: 20px 0;
  background-color: $main;
  color: #fff;
  font-size: 1.4rem;
}

.cookie {
  padding: 3px 10px;
  position: fixed;
  border: 1px solid $main;
  border-bottom: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  font-size: 1.2rem;
  z-index: 100;
  &__link {
    border-bottom: 1px solid $main;
  }

  &__btn {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid $main;
    font-size: 1.1rem;
  }
}
