.blog {
  &--bg {
    background-color: $background;
  }

  &__section-col {
    word-break: break-word;
    padding: 20px 0;
    box-sizing: border-box;
    @include media($breakpointLg) {
      padding: 38px 80px;
      flex: 0 0 33.333%;
    }

    &:first-child {
      @include media($breakpointLg) {
        background-color: #f9f9f9;
      }
    }
  }

  &__section-header {
    font-size: 2.4rem;
    color: $gray;
  }

  &__header-bg {
    padding: 30px 0;
    background-color: $background;
  }

  &__header {
    font-size: 1.8rem;
  }

  &__date {
    color: $gray;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 3.3rem;
  }

  &__image {
    margin: 20px 0;
    max-height: 200px;
  }
}
