.header {
}

.logo {
  width: 150px;
  height: auto;

  @include media($breakpointLg) {
    width: 275px;
  }
}

.burger {
  position: relative;
  width: 30px;
  height: 40px;
  z-index: 1000;

  @include media($breakpointMd) {
    display: none;
  }

  &__bar {
    position: relative;
    width: 100%;
    height: 3px;
    background-color: $main;
    transition: 300ms;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $main;
    }

    &:before {
      top: -8px;
      transition: 150ms 150ms top, 150ms transform, 300ms background-color;
    }

    &:after {
      bottom: -8px;
      transition: 150ms 150ms bottom, 150ms transform, 300ms background-color;
    }
  }

  &--active &__bar {
    &::before,
    &:after {
      background-color: darken($main, 20);
    }

    &:before {
      top: 0;
      transform: rotate(45deg);
      transition: 150ms top, 150ms 150ms transform;
    }
    &:after {
      bottom: 0;
      transform: rotate(-45deg);
      transition: 150ms bottom, 150ms 150ms transform;
    }
  }
}

.navbar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $main;
  transition: 300ms;
  transform: translateX(100%);
  z-index: 999;

  &--active {
    transform: translateX(0);
  }

  &--footer {
    position: static;
    background-color: transparent;
    transform: translateX(0);
  }

  @include media($breakpointMd) {
    position: static;
    transform: translateX(0);
  }
}

.nav {
  padding-top: 50px;
  height: 100%;

  @include media($breakpointMd) {
    padding-top: 0;
  }

  &__link {
    padding: 20px 0 20px 50px;
    display: block;
    color: #fff;
    text-align: right;
    font-weight: 500;
    transition: 200ms;

    @include media($breakpointMd) {
      padding: 20px 50px;
      text-align: center;
    }
    @include media($breakpointLg) {
      padding: 40px 50px;
      text-align: center;
    }

    &--active {
      background-color: $sub;
    }

    &:hover {
      background-color: $sub;
    }
  }

  &--footer &__link {
    padding: 10px 0;
    position: relative;

    &--active {
      background-color: transparent;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $sub;
      height: 2px;
      width: 100%;
      transform-origin: left center;
      transform: scaleX(0);
      transition: 200ms;
    }
    &:hover {
      background-color: transparent;
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

.sponsors {
  order: 3;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 0 100%;
  @include media($breakpointMd) {
    flex: 0 0 auto;
  }

  &__img-box {
    flex: 0 1 50%;
    @include media($breakpointSm) {
      flex-basis: 25%;
    }
    @include media($breakpointMd) {
      flex-basis: auto;
    }
  }

  &__img {
    object-fit: contain;
    object-position: center;
  }
}
