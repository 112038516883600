.gallery {
  // padding-left: 50px;
  box-sizing: border-box;
  // transform: scaleY(0);
  // transform-origin: center top;
  // transition: 300ms;
  // &--active &__wrapper {
  //   display: flex;
  // }

  &__category {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: $main;
    }
  }

  &__header {
    font-size: 1.8rem;
    columns: $gray;
    cursor: pointer;
  }

  &__wrapper {
    margin: 0 -10px;
    padding: 0;
  }

  &__item {
    position: relative;
    padding: 10px;
    flex: 1 0 100%;
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;

    @include media($breakpointSm) {
      flex-basis: 50%;
    }
    @include media($breakpointLg) {
      flex-basis: 25%;
    }
  }

  &__figure {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(transparent, #000);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: cover;
    object-position: center;
  }

  &__caption {
    position: absolute;
    bottom: 5px;
    left: 10%;
    right: 50px;
    color: #fff;
    text-align: left;
  }

  &__btn {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 48px;
    height: 48px;
    background: url("http://localhost:3000/images/gallery.svg") center / cover
      no-repeat;
    z-index: 99;
  }
}
