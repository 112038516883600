$breakpointXXs: 450px;
$breakpointXs: 600px;
$breakpointSm: 768px;
$breakpointMd: 992px;
$breakpointLg: 1200px;
$breakpointXl: 1500px;
$breakpointXXl: 2000px;

$main: #221c4f;
$sub: #f8a400;
$mainLight: #ffba80;
$background: #ececec;
$gray: #757575;

$font: "Acumin Pro", sans-serif;

@mixin media($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@import "reset";
@import "layout";
@import "universal";
@import "header";
@import "footer";
@import "banner";
@import "blog";
@import "gallery";
@import "contact";
@import "loader";
@import "progressbar";
