.mgtb {
  &-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &-40 {
    margin-top: 20px;
    margin-bottom: 20px;
    @include media($breakpointLg) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @include media($breakpointXl) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  &-50 {
    margin-top: 15px;
    margin-bottom: 15px;
    @include media($breakpointMd) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @include media($breakpointLg) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include media($breakpointXl) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  &-60 {
    margin-top: 15px;
    margin-bottom: 15px;
    @include media($breakpointMd) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @include media($breakpointLg) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include media($breakpointXl) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
  &-80 {
    margin-top: 30px;
    margin-bottom: 30px;
    @include media($breakpointMd) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @include media($breakpointLg) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
    @include media($breakpointXl) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
  &-160 {
    margin-top: 160px;
    margin-bottom: 160px;
  }
}

.mgt {
  &-10 {
    margin-top: 10px;
  }
  &-20 {
    margin-top: 20px;
  }

  &-40 {
    margin-top: 40px;
  }

  &-70 {
    margin-top: 30px;
    @include media($breakpointMd) {
      margin-top: 50px;
    }
    @include media($breakpointXl) {
      margin-top: 70px;
    }
  }
}

.mgb {
  &-10 {
    margin-bottom: 10px;
  }
  &-30 {
    margin-bottom: 15px;
    @include media($breakpointMd) {
      margin-bottom: 30px;
    }
  }
  &-20 {
    margin-bottom: 15px;
    @include media($breakpointMd) {
      margin-bottom: 20px;
    }
  }
  &-60 {
    margin-bottom: 15px;
    @include media($breakpointMd) {
      margin-bottom: 30px;
    }
    @include media($breakpointLg) {
      margin-bottom: 60px;
    }
  }

  &-80 {
    margin-bottom: 30px;
    @include media($breakpointMd) {
      margin-bottom: 40px;
    }
    @include media($breakpointLg) {
      margin-bottom: 60px;
    }
    @include media($breakpointXl) {
      margin-bottom: 80px;
    }
  }

  &-160 {
    margin-bottom: 40px;
    @include media($breakpointMd) {
      margin-bottom: 80px;
    }
    @include media($breakpointLg) {
      margin-bottom: 120px;
    }
    @include media($breakpointXl) {
      margin-bottom: 160px;
    }
  }
}

.mgr {
  &-5 {
    margin-right: 5px;
  }
  &-10 {
    margin-right: 10px;
  }
  &-20 {
    margin-right: 20px;
  }
}

.mgl {
  &-60 {
    margin-left: 10px;
    @include media($breakpointMd) {
      margin-left: 20px;
    }
    @include media($breakpointLg) {
      margin-left: 60px;
    }
  }
}

.mglr {
  &-70 {
    margin-left: 10px;
    margin-right: 10px;
    @include media($breakpointMd) {
      margin-left: 30px;
      margin-right: 30px;
    }
    @include media($breakpointLg) {
      margin-left: 50px;
      margin-right: 50px;
    }
    @include media($breakpointXl) {
      margin-left: 70px;
      margin-right: 70px;
    }
  }

  &-30 {
    margin-left: 15px;
    margin-right: 15px;

    @include media($breakpointLg) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  &-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  &-5 {
    @include media($breakpointXs) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.pdtb {
  &-70 {
    padding-top: 20px;
    padding-bottom: 20px;
    @include media($breakpointMd) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include media($breakpointXl) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  &-140 {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media($breakpointSm) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @include media($breakpointLg) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @include media($breakpointXl) {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }

  &-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.col-2 {
  @include media($breakpointSm) {
    columns: 2;
    column-gap: 50px;
    // column-rule: 1px solid #000;
  }
  @include media($breakpointXl) {
    column-gap: 200px;
  }
}

.line {
  height: 3px;
  &--orange {
    background-color: $sub;
  }
}

.section {
  h1 {
    color: $sub;
  }
  h2,
  h3,
  h4 {
    margin-top: 15px;
    margin-bottom: 15px;
    @include media($breakpointMd) {
      margin-top: 30px;
    }
    @include media($breakpointLg) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    @include media($breakpointXl) {
      margin-top: 50px;
      margin-bottom: 25px;
    }
    color: $sub;
  }

  strong {
    color: $main;
  }

  ul {
    padding-left: 15px;
    box-sizing: border-box;

    li {
      margin: 10px 0;
      &:before {
        content: "";
        display: inline-block;
        margin-right: 8px;
        width: 4px;
        height: 4px;
        background-color: $main;
        transform: translateY(-3px);
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}

.btn {
  padding: 10px 20px;
  border: 1px solid $main;
  color: $main;
}

.arrows {
  padding-right: 20px;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-right: 2px solid $sub;
    border-top: 2px solid $sub;
    transform: translateY(-50%) rotate(45deg);
  }

  &:before {
    right: 7px;
  }
  &:after {
    right: 0;
  }
}
