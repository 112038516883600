.contact {
  &__row {
    margin-left: -30px;
    margin-right: -30px;
  }

  &__item {
    margin: 60px 0;
    flex: 1;

    &:first-child {
      margin-top: 30px;
    }

    @include media($breakpointMd) {
      padding: 0 30px;
      // &:first-child {
      //   margin-left: 0;
      // }
      // &:last-child {
      //   margin-right: 0;
      // }
    }

    h3 {
      color: $main;
    }
  }

  &__header {
    margin-bottom: 0;
    line-height: 1;
    font-weight: 300;
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__logo {
    display: block;
    margin: 24px 0;
    height: 50px;
    object-fit: contain;
    object-position: center;
  }

  &__name {
    min-height: 3em;
  }

  &__link {
    padding-top: 20px;
    display: block;
    width: 100%;
    border-top: 1px solid $gray;
  }

  &--footer {
    border-top: 1px solid $background;

    @include media($breakpointMd) {
      padding: 40px 0;
    }
  }

  &--footer &__item {
    position: relative;
    padding-left: 20px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: $sub;
    }
  }

  &--footer &__link {
    border-top: none;
  }
}
