.progressbar {
  &__item {
    margin: 0 25px;
    max-width: 220px;
    flex: 0 1 33.333%;
    box-sizing: border-box;
  }

  &__label {
    color: #fff;
    font-size: 14px;
    font-weight: 300;

    & strong {
      color: $sub;
      font-size: 18px;
    }
  }
}
