.banner {
  position: relative;
  height: 200px;
  background: center / cover no-repeat;

  @include media($breakpointLg) {
    height: 395px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(transparent, #000);
    z-index: 0;
  }

  &--main {
    padding: 40px 0;
    height: auto;
    min-height: calc(100vh - 200px);
    box-sizing: border-box;

    @include media($breakpointLg) {
      height: auto;
      min-height: calc(100vh - 200px);
    }
  }

  &__content-box {
    position: relative;
    z-index: 2;
  }

  &__content-col {
    flex: 1;
  }

  &__content {
    h2,
    h3 {
      color: $mainLight;
      font-size: 1.6rem;
      font-weight: 400;
    }

    p {
      color: #fff;
    }
  }

  &__header {
    position: absolute;
    bottom: 0;
    color: #fff;
    font-weight: 300;
  }

  &__subheader {
    font-size: 2.3rem;
    font-weight: 300;
  }
}
